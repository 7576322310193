.navbar {
  background-color: var(--l-gray);
  box-shadow: 0 0.5rem 0.5rem -0.5rem var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  position: fixed;
  top: 0;
}

.navbar__brand {
  display: flex;
  left: 2rem;
  padding: 1rem;
  position: absolute;
}

.navbar__title {
  color: var(--purple);
  font-size: 1.2rem;
  font-weight: bold;
}

.navbar__title-text {
  color: var(--d-font);
}

.navbar__links {
  display: flex;
  list-style: none;
  padding: 0;
}

.navbar__link {
  font-size: 1.2rem;
  color: var(--d-font);
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-left: 2rem;
  margin-right: 2rem;
  text-decoration: none;
}

.navbar__link:hover {
  color: var(--purple);
}

.toggle {
  display: none;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  color: var(-d-font);
  font-size: 1rem;
  border: 1px solid var(--purple);
  cursor: pointer;
  background-color: transparent;
}

/* For screens smaller than 768px */
@media (max-width: 767px) {
  .navbar {
    align-items: center;
  }

  .navbar__links {
    display: none;
    position: absolute;
    top: 4rem;
    left: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background-color: #333;
    padding: 1rem;
  }
  .navbar__link {
    margin: 0.5rem 0;
  }

  .toggle {
    display: block;
  }

  #toggle:checked ~ .navbar__links {
    display: flex;
  }
}