.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(-d-font);
  padding: 0 1rem;
  height: 100vm;
}

.page__icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.page__button {
  margin: 1rem;
}

a[role='button'] {
  border-radius: 4px;
  display: block;
  text-decoration: none;
  text-align: center;
  color: var(--d-font);
  font-size: 1.2rem;
  border: 1px solid var(--purple);
  padding: 1rem;
  width: 39%;
  cursor: pointer;
  background-color: transparent;
}

a[role='button']:hover {
  color: var(--purple);
}