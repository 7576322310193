.explainer {
  background-color: var(--l-gray);
  width: 61vw;
  align-items: center;
  margin-top: 8vw;
  padding: 20px;
}

.explainer h1 {
  font-size: 3rem;
  text-align: left;
  margin-bottom: 4rem;
}

.explainer h3 {
  font-size: 1rem;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 4rem;
  line-height: 200%;
  padding-top: 0.61rem;
  max-width: 61%;
}

.explainer h3::before {
  content: "";
  display: block;
  width: 4rem;
  height: 2px;
  background: var(--purple);
  left: 0;
  top: 0;
  margin-bottom: 1rem;
}


.explainer p {
  font-size: 1.2rem;
  display: flex;
  justify-content: left;
  text-align: left;
  line-height: 150%;
  margin-bottom: 2rem;
}

.explainer ul {
  list-style-type: none;
}

.explainer li {
  font-size: 1.2rem;
  justify-content: left;
  text-align: left;
  line-height: 150%;
  padding-left: 1em;
  text-indent: -1em;
  list-style: none;
  unicode-range: U+2295;
}

.explainer li::before {
  content: "\2295";
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.tags {
  display: flex;
  width: 100%;
  justify-content: center;
}

.explainer__tag {
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  color: var(-d-font);
  font-size: 1rem;
  border: 1px solid var(--purple);
  padding: 0.5rem;
  background-color: transparent;
  margin: 1rem;
}