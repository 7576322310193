.home__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(-d-font);
  padding: 0 1rem;
  height: 100vm;
}

.home__button {
  margin: 1rem auto;
}